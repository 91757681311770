import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../../../commons/components/hoc/resizable'
import s from './calendar.scss'
import {MonthlyCalendar} from './monthly'
import {MobileMonthlyCalendar} from './monthly/mobile'
import {CalendarProps} from '.'

const MIN_WIDTH = 750
const MIN_MOBILE_WIDTH = 280

export const Calendar = ({isEditor, isMobile, closeMonthlyCalendarPopup, loading, resizeComponent}: CalendarProps) => {
  const [forceMobileLayout, setForceMobileLayout] = React.useState(false)

  const resize = (width: number, height: number) => {
    if (!isEditor && !isMobile) {
      closeMonthlyCalendarPopup()
    }

    if (width && height) {
      resizeComponent?.({width: undefined, height})

      const minWidth = isMobile ? MIN_MOBILE_WIDTH : MIN_WIDTH

      if (width < minWidth) {
        if (isEditor) {
          resizeComponent({width: minWidth, height})
        } else {
          setForceMobileLayout(true)
        }
      } else {
        setForceMobileLayout(false)
      }
    }
  }

  if (loading) {
    return null
  }

  const mobile = isMobile || forceMobileLayout

  return (
    <Resizable resizeFunc={resize}>
      <div className={classNames(s.calendar, {[s.mobile]: mobile})} data-hook="calendar-layout">
        {mobile ? <MobileMonthlyCalendar /> : <MonthlyCalendar />}
      </div>
    </Resizable>
  )
}
