import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import SideBySideItem from './side-by-side-item'
import s from './side-by-side.scss'
import {Utils} from './utils'
import {SideBySideProps} from '.'

export const SideBySide = ({
  showImage,
  showMembers,
  showRibbon,
  showSocial,
  showDescription,
  events,
  editor,
  margins,
  t,
  width,
  resizeComponent,
}: SideBySideProps) => {
  const utils = new Utils()

  const resizableProps = () => ({
    showImage,
    showMembers,
    showRibbon,
    showSocial,
    showDescription,
    eventCount: events.length,
  })

  const resize = () => {
    utils.setItemsHeight()

    if (editor) {
      utils.resizeContainer(resizeComponent, showImage)
    }
  }

  utils.resetItems()

  return (
    <Resizable {...resizableProps()} resizeFunc={resize}>
      <div className={s.container}>
        <Title />
        <ul className={s.items} data-hook="side-by-side-items" style={{minWidth: utils.getMinItemsWidth(showImage)}}>
          {events.map((event: ExtendedEvent, index: number) => (
            <li
              key={event.id}
              ref={element => utils.addItem(element)}
              className={classNames(s.item, utils.itemsHeightClass(width))}
              data-hook="side-by-side-item"
              style={{marginBottom: margins}}
            >
              <SideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
            </li>
          ))}
        </ul>
        <LoadMoreButton t={t} />
      </div>
    </Resizable>
  )
}
