import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import {Card} from './card'
import s from './cards.scss'
import {CardsProps} from '.'

const CARD_MAX_WIDTH = 500
const CARD_MIN_WIDTH = 200

export class Cards extends React.Component<CardsProps> {
  private container = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.props.editor) {
      this.resizeComponent()
    }
  }

  componentDidUpdate() {
    if (this.props.editor) {
      this.resizeComponent()
    }
  }

  resizeComponent = () => {
    const containerWidth = this.container.current?.clientWidth ?? 0
    const cardsMinWidth = this.getMinWidth()

    if (containerWidth && containerWidth <= cardsMinWidth) {
      this.props.resizeComponent(cardsMinWidth, this.container.current?.clientHeight)
    } else {
      this.props.resizeComponent(undefined, this.container.current?.clientHeight)
    }
  }

  getMinWidth = () => {
    const {columns, cardMargins, responsive} = this.props

    const cardWithMargins = CARD_MIN_WIDTH + cardMargins

    if (responsive) {
      return cardWithMargins + cardMargins
    }

    return cardWithMargins * columns + cardMargins
  }

  getMaxWidth = () => {
    const {columns} = this.props

    return columns * CARD_MAX_WIDTH
  }

  render() {
    const {events, t, cardMargins, columns} = this.props

    return (
      <div
        ref={this.container}
        className={s.container}
        style={{minWidth: this.getMinWidth(), maxWidth: this.getMaxWidth()}}
      >
        <Title />
        <ul className={s.cards} data-hook={DH.cards} style={{padding: cardMargins / 2}}>
          {events.map(event => (
            <Card
              event={event}
              t={t}
              columns={columns}
              key={event.id}
              minWidth={CARD_MIN_WIDTH}
              maxWidth={CARD_MAX_WIDTH}
            />
          ))}
        </ul>
        <LoadMoreButton t={t} />
      </div>
    )
  }
}
