import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../../../commons/components/hoc/resizable'
import scs from '../cards/card/card.scss'
import cs from '../cards/cards.scss'
import ls from '../list-layout/list-layout.scss'
import ss from '../single-event/single-event.scss'
import ts from '../single-event/title/title.scss'
import {getElementHeight} from '../../../../../commons/utils/dom'
import s from './empty-state.scss'
import {EmptyStateProps} from '.'

export const EmptyState = ({singleEventWidget, listLayout, t, resizeComponent}: EmptyStateProps) => {
  const resizeContainer = () => {
    const height = getElementHeight(`.${s.container}`)

    if (height) {
      resizeComponent?.({height, width: undefined})
    }
  }

  return (
    <Resizable resizeFunc={resizeContainer}>
      <div
        className={classNames(s.container, {
          [ss.fullLayout]: singleEventWidget,
          [ls.listContainer]: !singleEventWidget && listLayout,
          [cs.card]: !singleEventWidget && !listLayout,
          [ts.root]: singleEventWidget,
          [scs.evCardContentFont]: !singleEventWidget,
          [scs.evCardContentColor]: !singleEventWidget,
        })}
      >
        {t('widgetEmptyStateNotification')}
      </div>
    </Resizable>
  )
}
