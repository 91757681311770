import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../../commons/selectors/environment'
import {getCardsPerRow, getCardMargins, isResponsive, getComponentConfig} from '../../selectors/settings'
import {getEvents} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Cards as CardsPresentation} from './cards'
import {CardsOwnProps, CardsStateProps} from './interfaces'

const mapRuntimeToProps = ({state, host: {resizeComponent}}: AppProps): CardsStateProps => {
  const componentConfig = getComponentConfig(state)
  const events = getEvents(state)
  const responsive = isResponsive(getComponentConfig(state))

  return {
    events,
    columns: Math.min(getCardsPerRow(componentConfig), events.length),
    cardMargins: getCardMargins(componentConfig),
    editor: isEditor(state),
    responsive,
    resizeComponent: (width: number, height: number) => resizeComponent?.({width, height}),
  }
}

export const Cards = connect<CardsOwnProps, CardsStateProps>(mapRuntimeToProps)(withTranslation()(CardsPresentation))
export * from './interfaces'
