import {OLD_WIDGET_LAYOUT, WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {isMobile, isSite} from '../../../../commons/selectors/environment'
import {
  getImageWidth,
  isButtonLayout,
  isSingleEventWidget,
  includeImageToCalculations,
  isAllComponentsHidden,
  isImageVisible,
  getComponentConfig,
} from '../selectors/settings'
import {getElementHeight, getElementStyle, getElementWidth, setElementStyle} from '../../../../commons/utils/dom'
import {getFirstEvent} from '../selectors/events'
import {State} from '../types/state'

const CONTENT_SELECTOR: string = '#ev-content'
const CONTENT_CONTAINER_SELECTOR: string = '#ev-content-container'
const FRAME_SELECTOR: string = '#ev-frame'
const BTN_SELECTOR: string = '#ev-rsvp-button'

export const resizeToDefault = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
  state: State,
  layout?: string | number,
) => {
  switch (layout) {
    case OLD_WIDGET_LAYOUT.FULL:
    case WIDGET_LAYOUT.FULL:
      resizeToFullLayout(resizeComponent, state)
      break
    case OLD_WIDGET_LAYOUT.VERTICAL:
    case WIDGET_LAYOUT.VERTICAL:
      resizeToVerticalLayout(resizeComponent, state)
      break
    case OLD_WIDGET_LAYOUT.BACKGROUND:
    case WIDGET_LAYOUT.BACKGROUND:
      resizeToBackgroundLayout(resizeComponent)
      break
    case OLD_WIDGET_LAYOUT.BUTTON_ONLY:
    case WIDGET_LAYOUT.BUTTON_ONLY:
      resizeToButtonOnlyLayout(resizeComponent, state)
      break
    default:
      resizeWidget(resizeComponent, state)
      break
  }
}

const resizeToFullLayout = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
  state: State,
) => resizeComponent({width: isImageVisible(state) ? 860 : 550, height: getMinWidgetHeight(state)})

const resizeToVerticalLayout = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
  state: State,
) => {
  let height = getMinWidgetHeight(state)

  if (isImageVisible(state)) {
    height = (height * 100) / (100 - getImageWidth(getComponentConfig(state)))
  }

  resizeComponent({width: 600, height})
}

const resizeToBackgroundLayout = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
) => resizeComponent({width: 550, height: 550})

const resizeToButtonOnlyLayout = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
  state: State,
) => resizeComponent({width: getMinWidgetWidth(state) + 2, height: getMinWidgetHeight(state) + 2})
// + 2 - space around for outline

const resizeWidget = (
  resizeComponent: (options: {width: number; height: number; mobileResize?: boolean}) => Promise<void>,
  state: State,
) => {
  const mobile = isMobile(state)
  const minHeight = getMinWidgetHeight(state)

  if (mobile || getCurrentWidgetHeight(state) < minHeight) {
    resizeComponent({height: minHeight, width: undefined})
  }

  if (mobile && isSite(state)) {
    setElementStyle(getElement(state, FRAME_SELECTOR), 'height', `${minHeight}px`)
  }
}

const getMinWidgetWidth = (state: State) =>
  getWidgetButtonsWidth(state) + getWidgetBorderWidth(state) + getWidgetHorizontalPadding(state)

const getMinWidgetHeight = (state: State) => {
  const contentHeight = getContentHeight(state)
  const imageHeight = includeImageToCalculations(state) ? getImageHeight(state) : 0

  return contentHeight + imageHeight
}

const getCurrentWidgetHeight = (state: State) => getElementHeight(FRAME_SELECTOR, getContainer(state))

const getCurrentContentHeight = (state: State) => getElementHeight(CONTENT_SELECTOR, getContainer(state))

const getContentHeight = (state: State) =>
  getWidgetBorderWidth(state) + getWidgetVerticalPadding(state) + getCurrentContentHeight(state)

const getImageHeight = (state: State) => {
  const imageWidth = getImageWidth(getComponentConfig(state))
  return (getContentHeight(state) / (100 - imageWidth)) * imageWidth
}

const getWidgetButtonsWidth = (state: State) => getElementWidth(BTN_SELECTOR, getContainer(state))

const getWidgetBorderWidth = (state: State) =>
  (parseInt(getElementStyle(getElement(state, FRAME_SELECTOR), 'borderWidth'), 10) || 0) * 2

const getWidgetHorizontalPadding = (state: State) =>
  (parseInt(getElementStyle(getElement(state, CONTENT_CONTAINER_SELECTOR), 'paddingRight'), 10) || 0) * 2

const getWidgetVerticalPadding = (state: State) =>
  isSingleEventWidget(getComponentConfig(state)) &&
  !isButtonLayout(getComponentConfig(state)) &&
  !isAllComponentsHidden(state, getFirstEvent(state))
    ? 108 // Emulate padding even if there is none
    : (parseInt(getElementStyle(getElement(state, CONTENT_CONTAINER_SELECTOR), 'paddingTop'), 10) || 0) * 2

const getContainer = (state: State) => document.querySelector(`#${state.component.id}`)

const getElement = (state: State, selector: string) =>
  isSite(state) ? getContainer(state).querySelector(selector) : document.querySelector(selector)
